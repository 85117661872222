import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import { authenticationService } from '../services/AuthenticationService'
import { Role } from '@/helpers/Role'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'LoginView',
      component: LoginView,
      alias: '/login'
    },
    {
      path: '/dashboard',
      name: 'home',
      component: HomeView,
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Frontline, Role.Operator] }
    },
    {
      path: '/forgot-password',
      name: 'ForgotPassword',
      component: ForgotPassword
    },
    {
      path: '/manage-frontline',
      name: 'FrontLineStaff',
      component: () => import('../views/FrontLine.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin] }
    },
    {
      path: '/manage-manager',
      name: 'frontline',
      component: () => import('../views/Manager.vue'),
      meta: { authorize: [Role.GAdmin, Role.ITAdmin] }
    },
    {
      path: '/manage-operator',
      name: 'operator',
      component: () => import('../views/Operator.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin] }
    },
    {
      path: '/manage-centre',
      name: 'centre',
      component: () => import('../views/CentreView.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin] }
    },
    {
      path: '/manage-pos',
      name: 'pos',
      component: () => import('../views/CentrePOS.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin] }
    },
    {
      path: '/manage-centre-city',
      name: 'centreCity',
      component: () => import('../views/CentreViewCity.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin] }
    },
    {
      path: '/manage-centre-airport',
      name: 'centreAirport',
      component: () => import('../views/CentreViewAirport.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin] }
    },
    {
      path: '/manage-customers/index',
      name: 'ManageCustomers',
      component: () => import('../views/customers/ManageCustomers.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Frontline] }
    },
    {
      path: '/corporate-customers/index',
      name: 'CorporateCustomers',
      component: () => import('../views/customers/CorporateCustomers.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Frontline] }
    },
    {
      path: '/corporate-customers/details/:id',
      name: 'CustomerDetails',
      component: () => import('../views/customers/CustomerDetails.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Frontline] }
    },
    {
      path: '/corporate-customers/details/:id/manage-price',
      name: 'PriceManagement',
      component: () => import('../views/customers/PriceManagement.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Frontline] }
    },
    {
      path: '/corporate-customers/details/:id/manage-discount',
      name: 'DiscountManagement',
      component: () => import('../views/customers/DiscountManagement.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Frontline] }
    },
    {
      path: '/discounts/index',
      name: 'ManageDiscounts',
      component: () => import('../views/customers/ManageDiscounts.vue'),
      meta: { authorize: [Role.GAdmin] }
    },
    {
      path: '/report/domestic',
      name: 'RSMReport',
      component: () => import('../views/report/RSMReport.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin] }
    },
    {
      path: '/report/exception-report',
      name: 'ExceptionReport',
      component: () => import('../views/report/ShipmentExceptionReport.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Operator, Role.Manager] }
    },
    {
      path: '/report/fsm',
      name: 'FSMReport',
      component: () => import('../views/report/FSMReport.vue'),
      meta: { authorize: [Role.GAdmin] }
    },
    {
      path: '/report/gsa',
      name: 'GSAReport',
      component: () => import('../views/report/GSAReport.vue'),
      meta: { authorize: [Role.GAdmin] }
    },
    {
      path: '/printsub/:id',
      name: 'PrintSub',
      component: () => import('../views/PrintTag.vue'),
      meta: { authorize: [Role.Frontline, Role.Operator] }
    },
    {
      path: '/printwaybill/:id',
      name: 'PrintWaybill',
      component: () => import('../views/PrintWaybill.vue'),
      meta: { authorize: [Role.Frontline, Role.Operator] }
    },
    {
      path: '/printcorpwaybill/:id',
      name: 'BulkWaybill',
      component: () => import('../views/BulkWaybill.vue'),
      meta: { authorize: [Role.Frontline, Role.Operator] }
    },
    {
      path: '/printcorpbulkwaybill/:id',
      name: 'BulkPrintWaybill',
      component: () => import('../views/BulkPrintWaybill.vue'),
      meta: { authorize: [Role.Frontline, Role.Operator] }
    },
    {
      path: '/fprintwaybill/:id',
      name: 'FPrintWaybill',
      component: () => import('../views/FPrintWaybill.vue'),
      meta: { authorize: [Role.Frontline, Role.Operator] }
    },
    {
      path: '/iprintwaybill/:id',
      name: 'IPrintWaybill',
      component: () => import('../views/IPrintWaybill.vue'),
      meta: { authorize: [Role.Frontline, Role.Operator] }
    },
    {
      path: '/report/frontline/rsm',
      name: 'RSMFrontlineReport',
      component: () => import('../views/report/RSMShipmentReport.vue'),
      meta: { authorize: [Role.GAdmin, Role.Frontline] }
    },
    {
      path: '/report/fno',
      name: 'FnoReport',
      component: () => import('../views/report/FNOReport.vue'),
      meta: { authorize: [Role.ITAdmin, Role.GAdmin, Role.Admin] }
    },
    {
      path: '/report/frontline/fsm',
      name: 'FSMFrontlineReport',
      component: () => import('../views/report/FSMShipmentReport.vue'),
      meta: { authorize: [Role.GAdmin, Role.Frontline] }
    },
    {
      path: '/report/frontline/gsa',
      name: 'GSAFrontlineReport',
      component: () => import('../views/report/GSAShipmentReport.vue'),
      meta: { authorize: [Role.GAdmin, Role.Frontline] }
    },
    {
      path: '/report/frontline/int',
      name: 'INTFrontlineReport',
      component: () => import('../views/report/ImportShipmentReport.vue'),
      meta: { authorize: [Role.GAdmin, Role.Frontline] }
    },
    {
      path: '/operations/arrivals/pack-bag',
      name: 'PackBag',
      component: () => import('../views/operations/PackBag.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Operator] }
    },
    {
      path: '/gsaoperations/arrivals/new',
      name: 'ShipmentArrival',
      component: () => import('../views/gsaoperations/Arrival.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Operator] }
    },
    {
      path: '/operations/arrivals/pack-bag/:bagNo',
      name: 'BagDetails',
      component: () => import('../views/operations/BagDetails.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Operator] }
    },
    {
      path: '/gsaoperations/arrivals/bag/:bagNo',
      name: 'ShipmentBag',
      component: () => import('../views/gsaoperations/SBagDetails.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Operator] }
    },
    {
      path: '/gsaoperations/change/bag/:id',
      name: 'ShipmentItemBag',
      component: () => import('../views/gsaoperations/BagItemDetail.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Operator] }
    },
    {
      path: '/operations/weighing',
      name: 'WeighBag',
      component: () => import('../views/operations/WeighItems.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Operator] }
    },
    {
      path: '/gsaoperations/weighing',
      name: 'SWeighBag',
      component: () => import('../views/gsaoperations/WeighItems.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Operator] }
    },
    {
      path: '/operations/weighing/:bagNo',
      name: 'WeighBagItems',
      component: () => import('../views/operations/WeighBagItems.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Operator] }
    },
    {
      path: '/gsaoperations/dest/weighing/:bagNo',
      name: 'WeighDestBagItems',
      component: () => import('../views/gsaoperations/Destination/WeighBagItems.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Operator] }
    },
    {
      path: '/gsaoperations/weighing/:bagNo',
      name: 'GWeighBagItems',
      component: () => import('../views/gsaoperations/WeighBagItems.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Operator] }
    },
    {
      path: '/operations/origin-hub/debagging',
      name: 'DeBagging',
      component: () => import('../views/operations/DebagItem.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Operator] }
    },
    {
      path: '/operations/origin-hub/sorting',
      name: 'SortingList',
      component: () => import('../views/operations/SortingItems.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Operator] }
    },
    {
      path: '/gsaoperations/origin-hub/sorting',
      name: 'GSASortingList',
      component: () => import('../views/gsaoperations/SortingItems.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Operator] }
    },
    {
      path: '/gsaoperations/origin-hub/delayed',
      name: 'GSADelayedList',
      component: () => import('../views/gsaoperations/Delayed.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Operator] }
    },
    {
      path: '/operations/dmb-hub/arrivals',
      name: 'DMBArrivals',
      component: () => import('../views/operations/DMB/ArrivalsList.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Operator] }
    },
    {
      path: '/operations/dmb-hub/weighing',
      name: 'DMBWeighing',
      component: () => import('../views/operations/DMB/WeighArrivals.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Operator] }
    },
    {
      path: '/operations/destination/arrivals',
      name: 'DestinationArrivals',
      component: () => import('../views/operations/Destination/ArrivalsList.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Operator] }
    },
    {
      path: '/operations/destination/arrivals/:bagNo',
      name: 'ViewArrivals',
      component: () => import('../views/operations/Destination/ViewArrival.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Operator] }
    },
    {
      path: '/operations/destination/weighing',
      name: 'WeighingArrivals',
      component: () => import('../views/operations/Destination/WeighingArrivals.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Operator] }
    },
    {
      path: '/gsaoperations/destination/weighing/:bagNo',
      name: 'GSAWeighArrival',
      component: () => import('../views/operations/Destination/WeighArrival.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Operator] }
    },
    {
      path: '/gsaoperations/destination/arrivals',
      name: 'GSADestinationArrivals',
      component: () => import('../views/gsaoperations/Destination/ArrivalsList.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Operator] }
    },
    {
      path: '/gsaoperations/destination/arrivals/:bagNo',
      name: 'GSAViewArrivals',
      component: () => import('../views/gsaoperations/Destination/ViewArrival.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Operator] }
    },
    {
      path: '/gsaoperations/destination/weighing',
      name: 'GSAWeighingArrivals',
      component: () => import('../views/gsaoperations/Destination/WeighingArrivals.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Operator] }
    },
    // {
    //   path: '/operations/destination/debagging',
    //   name: 'DestinationDebagging',
    //   component: () => import('../views/operations/Destination/DestinationDebagging.vue'),
    //   meta: { authorize: [Role.GAdmin, Role.Admin, Role.Operator] }
    // },
    {
      path: '/operations/destination/debagging',
      name: 'DestinationDebagging',
      component: () => import('../views/operations/Destination/DebagAtDestination.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Operator] }
    },
    {
      path: '/operations/destination/sorting',
      name: 'DestinationSorting',
      component: () => import('../views/operations/Destination/DestinationSorting.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Operator] }
    },
    {
      path: '/operations/destination/sort-by-destination',
      name: 'SortByDestination',
      component: () => import('../views/operations/Destination/SortDestinationItem.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Operator] }
    },
    {
      path: '/gsaoperations/destination/sort-by-destination',
      name: 'GSASortByDestination',
      component: () => import('../views/gsaoperations/Destination/SortDestinationItem.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Operator] }
    },
    {
      path: '/operations/delivery/arrivals',
      name: 'DeliveryArrivals',
      component: () => import('../views/operations/Delivery/ArrivalsList.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Operator] }
    },
    {
      path: '/operations/delivery/deliveryBag/:id',
      name: 'ItemDetails',
      component: () => import('../views/operations/Delivery/ItemDetails.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Operator] }
    },
    {
      path: '/operations/delivery/delivery',
      name: 'StationDeliveries',
      component: () => import('../views/operations/Delivery/DeliveryLists.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Operator] }
    },
    {
      path: '/gsaoperations/delivery/delivery',
      name: 'GSAStationDeliveries',
      component: () => import('../views/gsaoperations/Delivery/DeliveryLists.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Operator] }
    },
    {
      path: '/operations/report/manifest-report',
      name: 'ManifestReport',
      component: () => import('../views/operations/ManifestReport.vue'),
      meta: { authorize: [Role.Operator] }
    },
    {
      path: '/operations/report/sdr-report',
      name: 'SdrReport',
      component: () => import('../views/operations/SDRReport.vue'),
      meta: { authorize: [Role.Operator] }
    },
    {
      path: '/operations/manifest-view/:id',
      name: 'ManifestItems',
      component: () => import('../views/operations/ManifestItems.vue'),
      meta: { authorize: [Role.Operator] }
    },
    {
      path: '/gsaoperations/report/manifest-report',
      name: 'GSAManifestReport',
      component: () => import('../views/gsaoperations/ManifestReport.vue'),
      meta: { authorize: [Role.Operator] }
    },
    {
      path: '/gsaoperations/unsettled-manifest',
      name: 'UnsettledManifest',
      component: () => import('../views/gsaoperations/UnsettledManifest.vue'),
      meta: { authorize: [Role.Operator] }
    },
    {
      path: '/gsaoperations/manifest-view/:id',
      name: 'GSAManifestItems',
      component: () => import('../views/gsaoperations/ManifestItems.vue'),
      meta: { authorize: [Role.Operator] }
    },
    {
      path: '/gsaoperations/manifest-item-view/:id',
      name: 'ManifestItemShipments',
      component: () => import('../views/gsaoperations/BagItems.vue'),
      meta: { authorize: [Role.Operator] }
    },
    {
      path: '/service/manage-service',
      name: 'ManageService',
      component: () => import('../views/service/ServiceManagement.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin] }
    },
    {
      path: '/service/manage-service-price',
      name: 'ServicePrice',
      component: () => import('../views/service/ServicePrice.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin] }
    },
    {
      path: '/service/manage-comm-price',
      name: 'CommodityPrice',
      component: () => import('../views/service/CommodityPrice.vue'),
      meta: { authorize: [Role.Admin] }
    },
    {
      path: '/service/manage-service-location',
      name: 'ServiceLocation',
      component: () => import('../views/service/ServiceLocManagement.vue'),
      meta: { authorize: [Role.GAdmin] }
    },
    {
      path: '/service/manage-special-price',
      name: 'SpecialServicePrice',
      component: () => import('../views/service/SpecialPriceManagement.vue'),
      meta: { authorize: [Role.GAdmin] }
    },
    {
      path: '/service/fedex-loc/index',
      name: 'FedExLoc',
      component: () => import('../views/service/FedexServiceLocation.vue'),
      meta: { authorize: [Role.GAdmin] }
    },
    {
      path: '/account_allocations',
      name: 'AccountAllocations',
      component: () => import('@/views/frontline/AccountAllocations.vue'),
      meta: { authorize: [Role.Frontline, Role.ITAdmin] }
    },
    {
      path: '/tag_allocations',
      name: 'TagAllocations',
      component: () => import('@/views/frontline/TagAllocations.vue'),
      meta: { authorize: [Role.Frontline, Role.ITAdmin] }
    },
    {
      path: '/bankaccount/index',
      name: 'BankAccounts',
      component: () => import('../views/admin/BankAccounts.vue'),
      meta: { authorize: [Role.GAdmin] }
    },
    {
      path: '/airports/index',
      name: 'ManageAirports',
      component: () => import('../views/admin/ManageAirports.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.ITAdmin] }
    },
    {
      path: '/city/index',
      name: 'ManageCity',
      component: () => import('../views/admin/ManageCities.vue'),
      meta: { authorize: [Role.GAdmin, Role.ITAdmin] }
    },
    {
      path: '/towns/index',
      name: 'ManageTowns',
      component: () => import('../views/admin/ManageTowns.vue'),
      meta: { authorize: [Role.GAdmin, Role.ITAdmin] }
    },
    {
      path: '/hubs/index',
      name: 'ManageHubs',
      component: () => import('../views/settings/HUBs.vue'),
      meta: { authorize: [Role.GAdmin, Role.ITAdmin] }
    },
    {
      path: '/foreigncity/index',
      name: 'ForeignCity',
      component: () => import('../views/admin/ForeignCities.vue'),
      meta: { authorize: [Role.GAdmin, Role.ITAdmin] }
    },
    {
      path: '/admin/subadmin/index',
      name: 'ManageSubAdmin',
      component: () => import('../views/admin/SubAdmin.vue'),
      meta: { authorize: [Role.ITAdmin] }
    },
    {
      path: '/admin/globaladmin/index',
      name: 'ManageGlobalAdmin',
      component: () => import('../views/admin/GlobalAdmin.vue'),
      meta: { authorize: [Role.ITAdmin] }
    },
    {
      path: '/admin/subsidiaries/index',
      name: 'ManageSbus',
      component: () => import('../views/admin/ManageSubsidiaries.vue'),
      meta: { authorize: [Role.ITAdmin] }
    },
    {
      path: '/fserviceprice/index',
      name: 'FreightSP',
      component: () => import('../views/service/FreightSP.vue'),
      meta: { authorize: [Role.GAdmin] }
    },
    {
      path: '/regions/index',
      name: 'ManageRegions',
      component: () => import('../views/settings/Regions.vue'),
      meta: { authorize: [Role.GAdmin, Role.ITAdmin] }
    },
    {
      path: '/exceptions',
      name: 'ManageExceptions',
      component: () => import('../views/settings/Exceptions.vue'),
      meta: { authorize: [Role.GAdmin, Role.ITAdmin] }
    },
    {
      path: '/regions/view/:id',
      name: 'ManageRegion',
      component: () => import('../views/settings/Region.vue'),
      meta: { authorize: [Role.GAdmin, Role.ITAdmin] }
    },
    {
      path: '/routines/index',
      name: 'ManageRoutines',
      component: () => import('../views/settings/Routines.vue'),
      meta: { authorize: [Role.GAdmin, Role.ITAdmin] }
    },
    {
      path: '/allocations',
      name: 'ManageAllocations',
      component: () => import('../views/AllocationAccount.vue'),
      meta: { authorize: [Role.GAdmin, Role.ITAdmin] }
    },
    {
      path: '/drivers/index',
      name: 'ManageDrivers',
      component: () => import('../views/settings/Drivers.vue'),
      meta: { authorize: [Role.Manager, Role.Operator, Role.ITAdmin] }
    },
    {
      path: '/regionCities/index',
      name: 'ManageRegionCities',
      component: () => import('../views/settings/RegionCities.vue'),
      meta: { authorize: [Role.GAdmin, Role.ITAdmin] }
    },
    {
      path: '/airportCities',
      name: 'ManageAirportCities',
      component: () => import('../views/admin/AirportCities.vue'),
      meta: { authorize: [Role.GAdmin, Role.ITAdmin] }
    },
    {
      path: '/rsmReport',
      name: 'RsmReport',
      component: () => import('../views/report/RSMReport.vue'),
      meta: { authorize: [Role.GAdmin] }
    },
    {
      path: '/onforwarding/index',
      name: 'OnForwarding',
      component: () => import('../views/service/ManageOnforwading.vue'),
      meta: { authorize: [Role.GAdmin] }
    },
    {
      path: '/changepassword',
      name: 'ChangePassword',
      component: () => import('../views/settings/ChangePassword.vue'),
      meta: { authorize: [] }
    },
    {
      path: '/broadcast/templates',
      name: 'SMS',
      component: () => import('../views/broadcast/SMS.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin] }
    },
    {
      path: '/broadcast/create',
      name: 'BroadCast',
      component: () => import('../views/broadcast/CreateBroadcast.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin] }
    },
    {
      path: '/shipment/rsm/new',
      name: 'NewShipment',
      component: () => import('../views/shipments/NewRSMShipment.vue'),
      meta: { authorize: [Role.Frontline] }
    },
    {
      path: '/shipment/frecordsale/new',
      name: 'NewGSAShipment',
      component: () => import('../views/shipments/NewShipment.vue'),
      meta: { authorize: [Role.Frontline] }
    },
    {
      path: '/shipment/intl/new',
      name: 'NewINTShipment',
      component: () => import('../views/shipments/NewIntShipment.vue'),
      meta: { authorize: [Role.Frontline] }
    },
    {
      path: '/shipment/import',
      name: 'ImportShipment',
      component: () => import('../views/shipments/ShipmentUpload.vue'),
      meta: { authorize: [Role.Frontline] }
    },
    {
      path: '/shipment/importManual',
      name: 'ImportManualShipment',
      component: () => import('../views/shipments/ShipmentManualUpload.vue'),
      meta: { authorize: [Role.Operator] }
    },
    {
      path: '/shipment/importCorpManual',
      name: 'ImportCorpManualShipment',
      component: () => import('../views/shipments/ShipmentManualCUpload.vue'),
      meta: { authorize: [Role.Operator] }
    },
    {
      path: '/shipment/customer-shipments',
      name: 'UploadDetails',
      component: () => import('../views/shipments/UploadDetails.vue'),
      meta: { authorize: [Role.Frontline] }
    },
    {
      path: '/shipment/daily/rsm',
      name: 'RSMDailyShipment',
      component: () => import('../views/shipments/RSMDailyReport.vue'),
      meta: { authorize: [Role.Frontline] }
    },
    {
      path: '/shipment/daily/intl',
      name: 'IntDailyShipment',
      component: () => import('../views/shipments/IntDailyReport.vue'),
      meta: { authorize: [Role.Frontline] }
    },
    {
      path: '/shipment/pickup/create',
      name: 'PickUp',
      component: () => import('../views/shipments/PickUp.vue'),
      meta: { authorize: [Role.Frontline] }
    },
    {
      path: '/shipment/pickup',
      name: 'ManagePickups',
      component: () => import('@/views/shipments/GeneratedPickups.vue'),
      meta: { authorize: [Role.Frontline] }
    },
    {
      path: '/operation/pickups/create',
      name: 'OperationPickUp',
      component: () => import('../views/operations/PickUp.vue'),
      meta: { authorize: [Role.Operator] }
    },
    {
      path: '/operation/pickups',
      name: 'ManageOperationPickups',
      component: () => import('@/views/operations/OperationPickups.vue'),
      meta: { authorize: [Role.Operator] }
    },
    {
      path: '/operation/courierbags',
      name: 'ManageCourierBags',
      component: () => import('@/views/operations/CourierManifests.vue'),
      meta: { authorize: [Role.Operator] }
    },
    {
      path: '/shipment/view',
      name: 'ShipmentView',
      component: () => import('@/views/shipments/ShipmentView.vue'),
      meta: { authorize: [Role.Frontline, Role.Operator] }
    },
    {
      path: '/shipment/lookup',
      name: 'ShipmentLookup',
      component: () => import('@/views/shipments/LookupShipment.vue'),
      meta: { authorize: [Role.Operator, Role.ITAdmin, Role.GAdmin] }
    },
    {
      path: '/shipment/:waybillNo',
      name: 'ShipmentEdit',
      component: () => import('@/views/shipments/ShipmentEdit.vue'),
      meta: { authorize: [Role.Operator] }
    },
    {
      path: '/shipment/dailysales',
      name: 'RSMDailySales',
      component: () => import('../views/shipments/dss/DailySales.vue'),
      meta: { authorize: [Role.Frontline] }
    },
    {
      path: '/operation/dailysales',
      name: 'OPDailySales',
      component: () => import('../views/operations/DailySales.vue'),
      meta: { authorize: [Role.Operator] }
    },
    {
      path: '/operation/dailyshipments',
      name: 'OPDailyShipments',
      component: () => import('../views/operations/OPSDailyReport.vue'),
      meta: { authorize: [Role.Operator] }
    },
    {
      path: '/admin/udailysales',
      name: 'RSMUDailySales',
      component: () => import('../views/admin/UnverifiedDailySales.vue'),
      meta: { authorize: [Role.ITAdmin] }
    },
    {
      path: '/shipment/dailysale/:id',
      name: 'RSMDailySale',
      component: () => import('../views/shipments/dss/DailySale.vue'),
      meta: { authorize: [Role.Frontline] }
    },
    {
      path: '/shipment/pods',
      name: 'PODs',
      component: () => import('../views/shipments/PODs.vue')
    },
    {
      path: '/shipment/discrepancy-shipments',
      name: 'DiscrepancyShipment',
      component: () => import('../views/shipments/DiscrepancyShipment.vue'),
      meta: { authorize: [Role.Frontline] }
    },
    {
      path: '/shipment/discrepancy-manifests',
      name: 'DiscrepancyManifest',
      component: () => import('../views/shipments/DiscrepancyManifest.vue'),
      meta: { authorize: [Role.Frontline] }
    },
    {
      path: '/expense/types',
      name: 'ExpenseType',
      component: () => import('../views/settings/ExpenseType.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin] }
    },
    {
      path: '/expense/claims',
      name: 'ExpenseClaimEmployee',
      component: () => import('../views/expenses/expense-claims.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin] }
    },
    {
      path: '/expense/employee_claims',
      name: 'ExpenseClaims',
      component: () => import('../views/expenses/expense-claims-general.vue'),
      meta: { authorize: [Role.Frontline] }
    },
    {
      path: '/expense/subsidiary_employee_claims',
      name: 'ExpenseClaimByUnit',
      component: () => import('../views/expenses/expense-claims-unit.vue'),
      meta: { authorize: [Role.Frontline] }
    },
    {
      path: '/expense/team_claims',
      name: 'ExpenseClaimTeam',
      component: () => import('../views/expenses/expense-claims-manager.vue'),
      meta: { authorize: [Role.Frontline] }
    },
    {
      path: '/expense/claims/new',
      name: 'NewClaim',
      component: () => import('../views/expenses/new-claim.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin] }
    },
    {
      path: '/expense/claims/edit/:id',
      name: 'EditClaim',
      component: () => import('../views/expenses/edit-claim.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin] }
    },
    {
      path: '/expense/cash_advance',
      name: 'CashAdvanceEmployees',
      component: () => import('../views/expenses/cash_advance/cash-advance.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin] }
    },
    {
      path: '/expense/cash_advance_team',
      name: 'CashAdvanceTeam',
      component: () => import('../views/expenses/cash_advance/teamCashAdvance.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin] }
    },
    {
      path: '/expense/cash_advance/new',
      name: 'NewAdvance',
      component: () => import('../views/expenses/cash_advance/new-advance.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin] }
    },
    {
      path: '/expense/cash_advance/edit/:id',
      name: 'EditAdvance',
      component: () => import('../views/expenses/cash_advance/edit-advance.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin] }
    },
    {
      path: '/expense/employee_cash_retirements',
      name: 'CashRetirementTeam',
      component: () => import('../views/expenses/cash_advance/teamCashRetirement.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin] }
    },
    {
      path: '/shipment/importation',
      name: 'FedExInvoice',
      component: () => import('../views/shipments/FedExInvoice.vue'),
      meta: { authorize: [Role.Frontline] }
    },
    {
      path: '/operational_managers',
      name: 'OperationalManagers',
      component: () => import('../views/operational-managers.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin] }
    },
    {
      path: '/shipment/home_office_shipment',
      name: 'HomeAndOffice',
      component: () => import('../views/shipments/HomeAndOffice.vue'),
      meta: { authorize: [Role.Frontline] }
    },
    {
      path: '/shipment/relocations',
      name: 'HomeOfficeRelocations',
      component: () => import('../views/shipments/Relocations.vue'),
      meta: { authorize: [Role.Frontline] }
    },
    {
      path: '/shipment/home_office_shipment/:id',
      name: 'RelocationEdit',
      component: () => import('../views/shipments/HomeOfficeEdit.vue'),
      meta: { authorize: [Role.Frontline] }
    },
    {
      path: '/settings/charge_settings',
      name: 'ChargeSettings',
      component: () => import('../views/settings/ChargeSettings.vue'),
      meta: { authorize: [Role.Frontline] }
    },
    {
      path: '/shipment/customer_shipment/:accountNo',
      name: 'CustomerShipments',
      component: () => import('../views/report/CustomerShipments.vue'),
      meta: { authorize: [Role.Frontline] }
    },
    {
      path: '/shipment/customer_transactions',
      name: 'CustomerTransactions',
      component: () => import('../views/finance/CustomerTransactions.vue'),
      meta: { authorize: [Role.GAdmin, Role.ITAdmin] }
    },
    {
      path: '/shipment/customer_invoices',
      name: 'CustomerInvoices',
      component: () => import('../views/finance/CustomerInvoices.vue'),
      meta: { authorize: [Role.GAdmin, Role.ITAdmin] }
    },
    {
      path: '/report/shipment-report',
      name: 'ShipmentDetailReport',
      component: () => import('../views/report/ShipmentReport.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Manager] }
    },
    {
      path: '/report/unattended-shipment-report',
      name: 'ShipmentUnAttendedReport',
      component: () => import('../views/operations/UnattendedShipment.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Manager] }
    },
    {
      path: '/report/returned-shipment-report',
      name: 'ShipmentReturnedReport',
      component: () => import('../views/operations/ReturnedShipment.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Manager] }
    },
    {
      path: '/report/fsm-shipment-report',
      name: 'FSMShipmentDetailReport',
      component: () => import('../views/report/IntlShipmentReport.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Manager] }
    },
    {
      path: '/report/import-shipment-report',
      name: 'ImportShipmentDetailReport',
      component: () => import('../views/report/ImportShipmentReport.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Manager] }
    },
    {
      path: '/dss/daily-sale-bill/:id',
      name: 'DailySaleBill',
      component: () => import('../views/shipments/dss/DailySaleBill.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Manager] }
    },
    {
      path: '/report/manifest-sheet/:no',
      name: 'ManifestSheet',
      component: () => import('../views/operations/ManifestSheet.vue'),
      meta: { authorize: [Role.GAdmin, Role.Admin, Role.Manager] }
    },
    {
      path: '/operation/manifest-sheet/:no',
      name: 'OperatorManifest',
      component: () => import('../views/operations/OperatorManifest.vue'),
      meta: { authorize: [Role.Operator] }
    },
    {
      path: '/it/support',
      name: 'ITSupport',
      component: () => import('../views/it-support.vue'),
      meta: { authorize: [Role.ITAdmin] }
    },
    {
      path: '/operation/support',
      name: 'OPSupport',
      component: () => import('../views/operations/op-support.vue'),
      meta: { authorize: [Role.Operator, Role.Manager] }
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  // const currentUser = authenticationService.currentUserValue
  // if (to.meta.requiresAuth && !currentUser) {
  //   next({ path: '/' }); alert('Session expired. Please login again to your account')
  // } else next()
  const { authorize } = to.meta
  const currentUser = authenticationService.currentUserValue

  if (authorize) {
    if (!currentUser) {
      // not logged in so redirect to login page with the return url
      next({ path: '/login' }); alert('Session expired. Please login again to your account')
    }
    // const p = currentUser.employee
    //   ? currentUser.employee.status === 3
    //     ? 'onboarddetails'
    //     : 'employeedashboard'
    //   : 'companies'
    // check if route is restricted by role
    if (authorize.length && currentUser.roles?.every(e => !authorize.includes(e))) {
      // role not authorised so redirect to home page
      if (currentUser.user.userType === 1) {
        return next({ name: 'dashboard' })
      }
      // } else if (currentUser.user.userType === 3) {
      //   return next({ name: p })
      // } else {
      //   return next({ path: '/' })
      // }
      // return next({ path: '/' })
    }
  }

  next()
})

export default router
